//project imports
import {
	siteDetailPath,
	siteAssetPath,
	siteDepartmentPath,
	modelDetail,
	modelStages,
	modelZones,
	modelIntervals,
	modelRoles,
	modelQuestions,
	modelTask,
	modelServiceLayout,
	modelAssest,
	analysisPath,
	defectsPath,
	feedbackPath,
	modelsPath,
	servicesPath,
	usersPath,
} from "helpers/routePaths";
import {
	APPLICATION,
	SITE_APP as SiteApp,
	HISTORY_CAPTIONS as historyCaptions,
} from "./constants-history";

//project constants
export const BASE_API_PATH = "/api/";

export const LICENSETYPE = {
	totalUsers: "Total Users",
	concurrentUsers: "Concurrent Users",
	perRoleWorkOrder: "Per Role / Work Order",
	siteBasedLicensing: "Site-Based Licencing",
	applicationBasedLicensing: "Application-Based Licencing",
};

export const clientOptions = [
	{ label: LICENSETYPE.totalUsers, value: 1 }, //0
	{ label: LICENSETYPE.concurrentUsers, value: 2 }, //1
	{ label: LICENSETYPE.perRoleWorkOrder, value: 3 }, //2
	{ label: LICENSETYPE.siteBasedLicensing, value: 4 }, //3
];

export const shareModelsOptions = [
	{ label: "No Sharing", value: 0 }, //0
	{ label: "Client: Site to Site", value: 1 }, //1
	{ label: "Client to Client Site", value: 2 }, //2
];

export const siteSettingShareModelsOptions = [
	{
		label:
			"You have the option to share models that you have built to another one of your sites listed within this client register. You will require access to these sites in order to share a model.",
		value: 1,
	}, //1
	{
		label:
			"You have the option to share models that you have built to other clients. You will require access to these clients and sites in order to share a model.",
		value: 2,
	}, //2
];
export const siteOptions = [
	{ label: LICENSETYPE.totalUsers, value: 1 }, //0
	{ label: LICENSETYPE.concurrentUsers, value: 2 }, //1
	{ label: LICENSETYPE.perRoleWorkOrder, value: 3 }, //2
	{ label: LICENSETYPE.applicationBasedLicensing, value: 5 }, //3
];

export const siteApplicationOptions = [
	{ label: LICENSETYPE.totalUsers, value: 1 }, //0
	{ label: LICENSETYPE.concurrentUsers, value: 2 }, //1
	{ label: LICENSETYPE.perRoleWorkOrder, value: 3 }, //2
];

export const HistoryInfo = {
	1: { icon: "historyadd", type: "added" },
	2: { icon: "historyupdate", type: "edited" },
	3: { icon: "historydelete", type: "deleted" },
};

export const SubTableModelTask = (customCaptions) => ({
	76: customCaptions["stage"],
	77: customCaptions["zone"],
	78: customCaptions["role"],
	79: customCaptions["interval"],
	80: customCaptions["part"],
	81: customCaptions["tool"],
	82: customCaptions["permit"],
	83: "Image",
	84: "Attachment",
	85: customCaptions["question"],
});

export const HistoryEntityCaptions = (customCaptions) => ({
	0: "Application",
	1: "Model Status",
	2: "Pause Reason",
	3: "Pause Subcategory",
	4: "Position",
	5: "Action",
	6: "Model Type",
	7: "System",
	8: "Operating Mode",
	9: "Role",
	10: "Defect Type",
	11: "Defect Status",
	12: "Defect Risk Rating",
	13: "Feedback Status",
	14: "Feedback Priority",
	15: "Feedback Classification",
	16: "Missing Part/Tool Reason",
	17: "Skip Task Reason",
	18: "Stop Reason",
	19: "Change Status Reason",
	20: "User",
	21: "Client",
	22: "Client Note",
	23: "Client Application",
	24: "Client Document",
	25: "Region",
	26: "Site",
	28: `Site ${customCaptions?.["department"] || "Department"}`,
	29: `Site ${customCaptions?.["asset"] || "Asset"}`,
	30: "Site Application",
	31: customCaptions?.["pauseReason"],
	32: `${customCaptions?.["pauseReason"]} Subcategory`,
	33: `Missing ${customCaptions?.["part"]}/${customCaptions?.["tool"]} Reason`,
	34: `${customCaptions?.["assetReference"]}`,
	35: `Report`,
	36: customCaptions?.["skipReason"],
	37: customCaptions?.["stopReason"],
	38: "Change Status Reason",
	39: `${customCaptions?.["model"]} Status`,
	40: `${customCaptions?.["model"]} Type`,
	41: customCaptions?.["actionRequired"],
	42: customCaptions?.["system"],
	43: customCaptions?.["operatingMode"],
	45: customCaptions?.["role"],
	46: customCaptions?.["position"],
	47: `${customCaptions?.["defect"]} ${customCaptions?.["riskRating"]}`,
	48: customCaptions?.["defectStatus"],
	49: customCaptions?.["defectType"],
	50: `${customCaptions?.["feedback"]} ${customCaptions?.["classification"]}`,
	51: `${customCaptions?.["feedback"]} ${customCaptions?.["priority"]}`,
	52: customCaptions?.["feedbackStatus"],
	53: "Site Application Key Contact",
	54: "Client User",
	55: "Client User Site",
	56: customCaptions?.["user"],
	57: "Client User Note",
	58: `${customCaptions?.["user"]} ${customCaptions?.["service"]} ${customCaptions?.["department"]}`,
	59: `${customCaptions?.["user"]} ${customCaptions?.["service"]} ${customCaptions?.["role"]}`,
	60: `${customCaptions?.["model"]}`,
	61: `${customCaptions?.["model"]} Version`,
	62: `${customCaptions?.["model"]} Document`,
	63: `${customCaptions?.["model"]} Note`,
	64: customCaptions?.["asset"],
	65: customCaptions?.["stage"],
	66: customCaptions?.["zone"],
	67: customCaptions?.["interval"],
	68: customCaptions?.["role"],
	69: customCaptions?.["question"],
	70: customCaptions?.["task"],
	71: customCaptions?.["taskListNo"],
	72: `${customCaptions?.["model"]} Version ${customCaptions?.["question"]} ${customCaptions?.["role"]}`,
	73: `${customCaptions?.["model"]} Version ${customCaptions?.["interval"]} Include`,
	74: `${customCaptions?.["model"]} Version ${customCaptions?.["question"]} Option`,
	75: `${customCaptions?.["model"]} ${customCaptions?.["task"]} Note`,
	76: `${customCaptions?.["task"]} ${customCaptions?.["stage"]}`,
	77: `${customCaptions?.["task"]} ${customCaptions?.["zone"]}`,
	78: `${customCaptions?.["task"]} ${customCaptions?.["role"]}`,
	79: `${customCaptions?.["task"]} ${customCaptions?.["interval"]}`,
	80: `${customCaptions?.["task"]} ${customCaptions?.["part"]}`,
	81: `${customCaptions?.["task"]} ${customCaptions?.["tool"]}`,
	82: `${customCaptions?.["task"]} ${customCaptions?.["permit"]}`,
	83: `${customCaptions?.["task"]} Image`,
	84: `${customCaptions?.["task"]} Attachment`,
	85: `${customCaptions?.["task"]} ${customCaptions?.["question"]}`,
	86: `${customCaptions?.["model"]} Version ${customCaptions?.["task"]} ${customCaptions?.["question"]} Option`,
	87: `${customCaptions?.["model"]} Import`,
	88: `${customCaptions?.["model"]} Import Action`,
	89: `${customCaptions?.["model"]} Import ${customCaptions?.["lubricant"]}`,
	90: `${customCaptions?.["model"]} Import ${customCaptions?.["operatingMode"]}`,
	91: `${customCaptions?.["model"]} Import ${customCaptions?.["system"]}`,
	92: `${customCaptions?.["model"]} Import ${customCaptions?.["role"]}`,
	93: `${customCaptions?.["model"]}  ${customCaptions?.["department"]}`,
	94: customCaptions?.["service"],
	95: `${customCaptions?.["user"]} ${customCaptions?.["service"]} ${customCaptions?.["model"]}`,
	96: ` ${customCaptions?.["service"]} ${customCaptions?.["task"]}`,
	97: ` ${customCaptions?.["service"]} Session`,
	98: ` ${customCaptions?.["service"]} ${customCaptions?.["task"]} ${customCaptions?.["question"]}`,
	99: ` ${customCaptions?.["service"]} ${customCaptions?.["task"]} ${customCaptions?.["question"]} Option`,
	100: ` ${customCaptions?.["service"]} Session ${customCaptions?.["question"]}`,
	101: ` ${customCaptions?.["service"]} Session ${customCaptions?.["question"]} Option`,
	102: `${customCaptions?.["service"]} Note`,
	103: `${customCaptions?.["service"]} Missing ${customCaptions?.["part"]} ${customCaptions?.["tool"]}`,
	104: `${customCaptions?.["service"]} ${customCaptions?.["pause"]}`,
	105: `${customCaptions?.["service"]} Status Change`,
	106: customCaptions?.["noticeboard"] || "Noticeboard",
	107: customCaptions?.["feedback"],
	108: `${customCaptions?.["feedback"]} Note`,
	109: `${customCaptions?.["feedback"]} Image`,
	110: customCaptions?.["defect"],
	111: `${customCaptions?.["defect"]} Note`,
	112: `${customCaptions?.["defect"]} Image`,
	113: `${customCaptions?.["defect"]} ${customCaptions?.["part"]}`,
	114: `${customCaptions?.["model"]} Version ${customCaptions?.["department"]}`,
	115: `Notice Board ${customCaptions?.["deparment"]}`,
	116: `${customCaptions?.["model"]} Version ${customCaptions?.["arrangement"]}`,
	117: `${customCaptions?.["model"]} Version ${customCaptions?.["task"]} ${customCaptions?.["arrangement"]}`,
	118: `Mobile Error`,
});

export const HistoryCaptions = {
	services: "services",
	modelVersionQuestions: "modelVersionQuestions",
	defects: "defects",
	clientUserSite: "clientUserSite",
	feedback: "feedback",
	noticeBoard: "noticeBoard",
	modelVersionTasks: "modelVersionTasks",
	modelVersionRoles: "modelVersionRoles",
	modelVersionIntervals: "modelVersionIntervals",
	modelVersionZones: "modelVersionZones",
	modelVersionStages: "modelVersionStages",
	modelVersionArrangements: "modelVersionArrangements",
	modelAssets: "modelAssets",
	modelVersions: "modelVersions",
	...historyCaptions,
};

export const HistoryCustomCaption = (from, customCaptions, showParts) => {
	switch (from) {
		case HistoryCaptions.modelVersions:
			return {
				DevModelVersionID: `Development ${customCaptions?.["model"]} Version`,
				ModelID: `${customCaptions?.["model"]}`,
				Version: "Version",
				ModelStatusID: `${customCaptions?.["model"]} Status`,
				Name: customCaptions?.["Make"],
				Model: customCaptions?.["model"],
				ModelName: `${customCaptions?.["model"]} Name`,
				ModelTypeID: `${customCaptions?.["modelType"]}`,
				SerialNumberRange: `${customCaptions?.["serialNumberRange"]}`,
				ImageKey: "Image Key",
				ThumbnailKey: "Thumbnail Image Key",
				ShowTimer: "Show Timer",
				AllowTickAllPartsTools: `All Tick All for ${
					showParts ? `${customCaptions?.["partPlural"]} and` : ""
				} ${customCaptions?.["toolPlural"]} `,
				ListPartsToolsByStageZone: `List ${
					showParts ? `${customCaptions?.["partPlural"]} /` : ""
				} ${customCaptions?.["toolPlural"]} by ${customCaptions?.["stage"]} / ${
					customCaptions?.["zone"]
				}`,
				HideParts: `Hide ${customCaptions?.["partPlural"]}`,
				HideTools: `Hide ${customCaptions?.["toolPlural"]}`,
				IsPublished: "Published",
				EnableIntervalAutoInclude: `Enable ${customCaptions?.["interval"]} Auto-Include`,
				ReviewDate: "Review Date",
				ImageURL: "Image URL",
				ThumbnailURL: "Thumbnail Image URL",
				ActiveModelVersionID: `Active ${customCaptions?.["model"]} Version`,
			};
		case HistoryCaptions.modelDocument:
			return {
				ModelID: `${customCaptions?.["model"]}`,
				DocumentKey: "Document Key",
				DocumentURL: "Document URL",
				Filename: "File Name",
				Date: "Date",
				Name: "Name",
			};

		case HistoryCaptions.modelNote:
			return {
				ModelID: `${customCaptions?.["model"]}`,
				Note: "note",
				Date: "Date",
				Name: "Name",
			};

		case HistoryCaptions.modelVersionStage:
			return {
				ModelVersionID: `${customCaptions?.["model"]} Version`,
				Name: "Name",
				ImageKey: "Image Key",
				ThumbnailKey: "Thumbnail Key",
				HasZones: `Has ${customCaptions?.["zone"]}`,
			};

		case HistoryCaptions.modelVersionZone:
			return {
				ModelVersionID: `${customCaptions?.["model"]} Version`,
				Name: "Name",
				ImageKey: "Image Key",
				ThumbnailKey: "Thumbnail Key",
			};

		case HistoryCaptions.modelVersionInterval:
			return {
				ModelVersionID: `${customCaptions?.["model"]} Version`,
				Name: "Name",
			};

		case HistoryCaptions.modelVersionRole:
			return {
				ModelVersionID: `${customCaptions?.["model"]} Version`,
				Name: "name",
				roleID: "role",
				siteDepartmentID: `site ${customCaptions?.["department"]}`,
			};

		case HistoryCaptions.modelVersionQuestion:
			return {
				ModelVersionID: `${customCaptions?.["model"]} Version`,
				ModelVersionStageID: `${customCaptions?.["model"]} Version ${customCaptions?.["stage"]}`,
				ModelVersionZoneID: `${customCaptions?.["model"]} Version ${customCaptions?.["zone"]}`,
				IsCompulsory: "Is Compulsory",
				Caption: "Caption",
				Timing: "Timing",
				Type: "Type",
				DecimalPlaces: "Decimal Places",
				CheckboxCaption: "Checkbox Caption",
			};
		case HistoryCaptions.modelAssets:
			return {
				ModelID: `${customCaptions?.["model"]}`,
				SiteAssetID: `Site ${customCaptions?.["asset"]}`,
				IsActive: "Is Active",
				ModelVersionArrangementID: `${customCaptions?.["model"]} Version ${customCaptions?.["arrangement"]}`,
			};

		case HistoryCaptions.applicationModelStatus:
			return {
				ApplicationID: "Application",
				Name: "Name",
				Publish: "Publish",
			};
		case HistoryCaptions.applicationPosition:
			return {
				ApplicationID: "Application",
				Name: "Name",
				ModelAccess: `${APPLICATION.ModelCC} Access`,
				NoticeboardAccess: `${APPLICATION.TutorialCC} Access`,
				FeedbackAccess: `${APPLICATION.FeedbackCC} Access`,
				UserAccess: `${APPLICATION.UserCC} Access`,
				SettingsAccess: "Settings Access",
				ServiceAccess: `${APPLICATION.ServiceCC} Access`,
				DefectAccess: `${APPLICATION.DefectCC} Access`,
				AnalyticsAccess: "Analytics Access",
				AssetAccess: `${APPLICATION.AssetCC} Access`,
				AllowPublish: "Allow Publish",
				DefaultPage: "Default Page",
			};
		case HistoryCaptions.applicationPauseSubcategory:
			return {
				ApplicationID: "Application",
				Name: "Name",
			};
		case HistoryCaptions.applicationChangeStatusReason:
			return {
				ApplicationID: "Application",
				Name: "Name",
			};

		case HistoryCaptions.user:
			return {
				Email: "Email",
				FirstName: "First Name",
				LastName: "LastName",
				Active: "Is Active",
				IsAdmin: "Is Admin",
				ReceiveFeedbackEmail: "Receive Feedback Email",
				DisplayName: "Name",
				AdminType: "Admin Type",
			};

		case HistoryCaptions.client:
			return {
				Name: "Name",
				LicensesType: "License Type",
				LicenseCount: "License Count",
				LogoKey: "Logo Key",
				IsActive: "Is Active",
				LogoURL: "Logo URL",
				LogoFilename: "Logo File Name",
			};

		case HistoryCaptions.clientNote:
			return {
				ClientID: "Client",
				Note: "Note",
				Name: "Name",
			};

		case HistoryCaptions.clientApplication:
			return {
				ClientID: "Client",
				ApplicationID: "Application",
				IsActive: "Is Active",
			};

		case HistoryCaptions.clientDocument:
			return {
				ClientID: "Client",
				DocumentKey: "Document Key",
				DocumentURL: "Document URL",
				Filename: "File Name",
			};

		case HistoryCaptions.region:
			return {
				ClientID: "Client",
				Name: "Name",
			};

		case HistoryCaptions.site:
			return {
				RegionID: "Region",
				Name: "Name",
				Company: "Company",
				Address: "Address",
				BusinessNumber: "Business Number",
				Licenses: "License Count",
				Licensetype: "License Type",
				RegionName: "Region Name",
				ClientName: "Client Name",
				ClientID: "Client",
			};

		case HistoryCaptions.siteDepartment:
			return {
				SiteID: "Site",
				Name: "Name",
				Description: "Description",
			};

		case HistoryCaptions.siteAsset:
			return {
				SiteID: "Site",
				Name: "Name",
				Description: "Description",
			};

		case HistoryCaptions.applicationAction:
			return {
				ApplicationID: "Application",
				Name: "Name",
			};
		case HistoryCaptions.applicationPause:
			return {
				ApplicationID: "Application",
				Name: "Name",
			};
		case HistoryCaptions.applicationModelType:
			return {
				ApplicationID: "Application",
				Name: "Name",
			};
		case HistoryCaptions.applicationSystem:
			return {
				ApplicationID: "Application",
				Name: "Name",
			};
		case HistoryCaptions.applicationDefectType:
			return {
				ApplicationID: "Application",
				Name: "Name",
			};
		case HistoryCaptions.applicationDefectStatus:
			return {
				ApplicationID: "Application",
				Name: "Name",
				Type: "Type",
			};
		case HistoryCaptions.applicationOperatingMode:
			return {
				ApplicationID: "Application",
				Name: "Name",
			};
		case HistoryCaptions.applicationDefectRiskRating:
			return {
				ApplicationID: "Application",
				Name: "Name",
				Action: "Action",
			};
		case HistoryCaptions.applicationFeedbackStatus:
			return {
				ApplicationID: "Application",
				Name: "Name",
				Type: "Type",
			};
		case HistoryCaptions.applicationRole:
			return {
				ApplicationID: "Application",
				Name: "Name",
				CanRegisterDefect: `Can Register ${APPLICATION.DefectCC}`,
			};
		case HistoryCaptions.applicationFeedbackPriority:
			return {
				ApplicationID: "Application",
				Name: "Name",
			};
		case HistoryCaptions.applicationSkipTaskReason:
			return {
				ApplicationID: "Application",
				Name: "Name",
			};
		case HistoryCaptions.applicationStopReason:
			return {
				ApplicationID: "Application",
				Name: "Name",
			};
		case HistoryCaptions.applicationFeedbackClassification:
			return {
				ApplicationID: "Application",
				Name: "Name",
			};
		case HistoryCaptions.applicationMissingPartToolReason:
			return {
				ApplicationID: "Application",
				Name: "Name",
			};

		case HistoryCaptions.modelVersionArrangements:
			return {
				Name: "Name",
			};
		case HistoryCaptions.modelVersionStages:
			return {
				ModelVersionID: `${customCaptions?.["model"]} Version`,
				Name: "Name",
				ImageKey: `Image Key`,
				ThumbnailKey: `Thumbnail Key`,
				HasZones: `Has ${customCaptions?.["zonePluralz"]}`,
			};
		case HistoryCaptions.modelVersionZones:
			return {
				ModelVersionID: `${customCaptions?.["model"]} Version`,
				Name: "Name",
				ImageKey: `Image Key`,
				ThumbnailKey: `Thumbnail Key`,
			};
		case HistoryCaptions.modelVersionIntervals:
			return {
				ModelVersionID: `${customCaptions?.["model"]} Version`,
				Name: "Name",
			};
		case HistoryCaptions.modelVersionTaskRole:
			return {
				ModelVersionTaskID: `${customCaptions?.["task"]}`,
				ModelVersionRoleID: `${customCaptions?.["tole"]}`,
			};
		case HistoryCaptions.modelVersionRoles:
			return {
				ModelVersionID: `${customCaptions?.["model"]} Version`,
				Name: "Name",
				RoleID: `${customCaptions?.["role"]}`,
				SiteDepartmentID: `Site ${customCaptions?.["department"]}`,
			};
		case HistoryCaptions.modelVersionQuestions:
			return {
				ModelVersionID: `${customCaptions?.["model"]} Version`,
				ModelVersionStageID: `${customCaptions?.["model"]} Version ${customCaptions?.["stage"]}`,
				ModelVersionZoneID: `${customCaptions?.["model"]} Version ${customCaptions?.["zone"]}`,
				IsCompulsory: "Is Compulsory",
				Caption: "Caption",
				Timing: "Timing",
				Type: "Type",
				DecimalPlaces: "Decimal Places",
				CheckboxCaption: "Checkbox Caption",
			};
		case HistoryCaptions.modelImportLubricant:
			return {
				ModelImportID: `${customCaptions?.["model"]} Import`,
				Name: "Name",
				LubricantID: `${customCaptions?.["lubricant"]}`,
				NewName: "New Name",
			};
		case HistoryCaptions.modelTaskNote:
			return {
				ModelID: `${customCaptions?.["model"]}`,
				Note: "Note",
				Date: "Date",
				Name: "Name",
			};
		case HistoryCaptions.modelImportOperatingMode:
			return {
				ModelImportID: `${customCaptions?.["model"]} Import`,
				Name: "Name",
				OperatingModeID: `${customCaptions?.["operatingMode"]}`,
				NewName: "New Name",
			};
		case HistoryCaptions.modelIntervalTaskListNo:
			return {
				ModelID: `${customCaptions?.["model"]}`,
				Name: "Name",
			};
		case HistoryCaptions.modelVersionQuestionRole:
			return {
				ModelVersionQuestionID: `${customCaptions?.["model"]} Question`,
				ModelVersionRoleID: `${customCaptions?.["role"]}`,
			};
		case HistoryCaptions.modelVersionTaskStage:
			return {
				ModelVersionTaskID: `${customCaptions?.["task"]}`,
				ModelVersionStageID: `${customCaptions?.["stage"]}`,
				SiteAssetID: `${customCaptions?.["asset"]} `,
				SiteAssetReferenceID: `${customCaptions?.["assetReference"]}`,
			};
		case HistoryCaptions.modelVersionTaskDocument:
			return {
				ModelVersionTaskID: `${customCaptions?.["task"]}`,
				Name: "Name",
				DocumentKey: "Document Key",
				Link: "Link",
				Filesize: "File Size",
				Filename: "File Name",
			};
		case HistoryCaptions.modelVersionTaskImage:
			return {
				ModelVersionTaskID: `${customCaptions?.["task"]}`,
				Description: "Description",
				ImageKey: "ImageKey",
				ThumbnailKey: "ThumbnailKey",
			};
		case HistoryCaptions.modelVersionTaskPermit:
			return {
				ModelVersionTaskID: `${customCaptions?.["task"]}`,
				Name: "Name",
			};
		case HistoryCaptions.modelVersionTaskZone:
			return {
				ModelVersionTaskID: `${APPLICATION?.["TaskCC"]}`,
				ModelVersionZoneID: `${APPLICATION?.["ZoneCC"]}`,
				SiteAssetID: `${APPLICATION?.["AssetCC"]}`,
				SiteAssetReferenceID: `${APPLICATION?.["AssetReferenceCC"]}`,
			};
		case HistoryCaptions.modelVersionTaskInterval:
			return {
				ModelVersionTaskID: `${customCaptions?.["task"]}`,
				ModelVersionIntervalID: `${customCaptions?.["interval"]}`,
			};
		case HistoryCaptions.modelVersionTaskTool:
			return {
				ModelVersionTaskID: `${customCaptions?.["task"]}`,
				Qty: "Qty",
				Name: "Name",
			};
		case HistoryCaptions.modelVersionTaskPart:
			return {
				ModelVersionTaskID: `${customCaptions?.["task"]}`,
				Qty: "Qty",
				Name: "Name",
				Description: "Description",
				StockNumber: `${customCaptions?.["partStockNumber"]}`,
			};
		case HistoryCaptions.modelVersionsQuestionOption:
			return {
				ModelVersionQuestionID: `${customCaptions?.["model"]} Question`,
				Name: "Name",
			};
		case HistoryCaptions.modelVersionIntervalInclude:
			return {
				ModelVersionIntervalID: `${customCaptions?.["model"]} ${customCaptions?.["interval"]}`,
				IncludeModelVersionIntervalID: `Include ${customCaptions?.["model"]} Version ${customCaptions?.["interval"]}`,
			};
		case HistoryCaptions.modelVersionTaskQuestion:
			return {
				ModelVersionTaskID: `${customCaptions?.["task"]}`,
				IsCompulsory: "Is Compulsory",
				Caption: "Caption",
				Type: "Type",
				DecimalPlaces: "Decimal Places",
				MinValue: "Minimum Value",
				MaxValue: "Maximum Value",
				CheckboxCaption: "Checkbox Caption",
			};
		case HistoryCaptions.modeImportSystem:
			return {
				ModelImportID: `${customCaptions?.["model"]} Import`,
				Name: "Name",
				SystemID: `${customCaptions?.["system"]}`,
				NewName: "New Name",
			};
		case HistoryCaptions.modeImportRole:
			return {
				ModelImportID: `${customCaptions?.["model"]} Import`,
				Name: "Name",
				RoleID: `${customCaptions?.["role"]} `,
				NewName: "New Name",
			};
		case HistoryCaptions.modelImportAction:
			return {
				ModelImportID: `${customCaptions?.["model"]} Import`,
				Name: "Name",
				ActionID: `${customCaptions?.["action"]} `,
				NewName: "New Name",
			};
		case HistoryCaptions.modelVersionTaskQuestionOption:
			return {
				ModelVersionTaskQuestionID: `${customCaptions?.["task"]} Question`,
				Name: "Name",
				RaiseDefect: `Raise ${customCaptions?.["defect"]}`,
			};
		case HistoryCaptions.modelImport:
			return {
				SiteAppID: "Site Application",
				Name: "Name",
				Model: `${customCaptions?.["model"]}`,
				SiteDepartmentID: "Site SiteApp.DepartmentCC",
				ModelTypeID: "SiteApp.ModelCC Type",
				SerialNumberRange: "Serial Number Range",
				DocumentKey: "Document Key",
				Source: "Import Source Type",
				SourceModelVersionID: "Source SiteApp.ModelCC Version",
			};
		case HistoryCaptions.modelVersionTasks:
			return {
				ModelVersionID: `${customCaptions?.["model"]} Version`,
				ActionID: `${customCaptions?.["actionRequired"]}`,
				OperatingModeID: `${customCaptions?.["operatingMode"]}`,
				SystemID: `${customCaptions?.["system"]}`,
				LubricantID: `${customCaptions?.["lubricant"]}`,
				Name: "Name",
				SafetyCritical: `${customCaptions?.["safetyCritical"]}`,
				EstimatedMinutes: "Estimated Minutes",
				NotSkippable: `Cannot Skip ${customCaptions?.["taskPlural"]}`,
				LubricantVolume: `${customCaptions?.["lubricant"]} Volume`,
				LubricantUOM: `${customCaptions?.["lubricant"]} Unit Of Measure`,
				Purpose: `${customCaptions?.["purpose"]}`,
				Procedure: `${customCaptions?.["procedure"]}`,
				PossibleHazards: `${customCaptions?.["possibleHazardsPlural"]}`,
				AdditionalPPE: `${customCaptions?.["additionalPPE"]}`,
				Specification: `${customCaptions?.["specification"]}`,
				ContaminationControl: `${customCaptions?.["contaminationControls"]}`,
				OtherInformation: `${customCaptions?.["otherInformation"]}`,
				CorrectiveActions: `${customCaptions?.["correctiveActionsPlural"]}`,
				CustomField1: `${customCaptions?.["customField1"]}`,
				CustomField2: `${customCaptions?.["customField2"]}`,
				CustomField3: `${customCaptions?.["customField3"]}`,
				Controls: `${customCaptions?.["controlsPlural"]}`,
				Isolations: `${customCaptions?.["isolationsPlural"]}`,
			};

		case HistoryCaptions.defects:
			return {
				ModelID: `${customCaptions?.["model"]}`,
				ModelVersionStageID: `${customCaptions?.["stage"]}`,
				ModelVersionZoneID: `${customCaptions?.["zone"]}`,
				ServiceID: `${customCaptions?.["service"]}`,
				SiteDepartmentID: `${customCaptions?.["department"]}`,
				ServiceTaskID: `${customCaptions?.["task"]}`,
				ServiceTaskQuestionID: `${customCaptions?.["task"]} ${customCaptions?.["question"]}`,
				SiteAssetID: `${customCaptions?.["asset"]}`,
				SiteAssetReferenceID: `${customCaptions?.["assetReference"]}`,
				DefectRiskRatingID: `${customCaptions?.["riskRating"]}`,
				DefectStatusID: `${customCaptions?.["defectStatus"]}`,
				DefectTypeID: `${customCaptions?.["defectType"]}`,
				WorkOrder: `${customCaptions?.["defectWorkOrder"]}`,
				Details: `Details`,
			};
		case HistoryCaptions.defectNote:
			return {
				DefectID: `${customCaptions?.["defect"]}`,
				Note: "Note",
				Date: "Date",
				Name: "Name",
			};
		case HistoryCaptions.defectImage:
			return {
				DefectID: `${customCaptions?.["defect"]}`,
				ImageKey: `Image Key`,
				ThumbnailKey: `Thumbnail Key`,
			};
		case HistoryCaptions.defectPart:
			return {
				DefectID: `${customCaptions?.["defect"]}`,
				Qty: `Qty`,
				Name: "Name",
				Description: "Description",
			};

		case HistoryCaptions.clientUser:
			return {
				UserID: "User",
				ClientID: "Client",
				IsAdmin: "Is Admin",
				Active: "Is Active",
				ExternalReference: "External Reference",
			};
		case HistoryCaptions.clientUserSite:
			return {
				Active: "Is Active",
				AllowAllModels: `Allow All ${customCaptions?.["modelPlural"]}`,
				PositionID: `${customCaptions?.["position"]}`,
				clientUserID: "clientUser",
				SiteID: "Site",
				SiteDepartmentID: `Site ${APPLICATION?.["DepartmentCC"]}`,
			};
		case HistoryCaptions.clientUserSiteAppServiceModel:
			return {
				ClientUserSiteAppID: `${customCaptions?.["user"]}`,
				ModelID: `${customCaptions?.["model"]}`,
			};

		case HistoryCaptions.clientUserSiteApp:
			return {
				ClientUserSiteID: "Client User Site",
				SiteAppID: "Site Application",
				PositionID: "Position",
				AllowAllModels: `Allow All ${customCaptions?.["modelPlural"]}`,
			};

		case HistoryCaptions.clientUserNote:
			return {
				ClientUserID: "Client User",
				Note: "Note",
				Date: "Date",
				Name: "Name",
			};

		case HistoryCaptions.clientUserSiteAppServiceDepartment:
			return {
				ClientUserSiteAppID: "Client User Site Application",
				SiteDepartmentID: `Site ${customCaptions?.["department"]}`,
			};

		case HistoryCaptions.clientUserSiteAppServiceRole:
			return {
				ClientUserSiteAppID: "Client User Site Application",
				RoleID: "Role",
			};

		case HistoryCaptions.model:
			return {
				SiteAppID: "Site Application",
				DevModelVersionID: `Development ${customCaptions?.["model"]} Version`,
				ActiveModelVersionID: `Active ${customCaptions?.["model"]} Version`,
				Type: "Type",
				IsSharable: "Is Sharable",
				Active: "Is Active",
			};
		case HistoryCaptions.services:
			return {
				SiteAppID: `Site Application`,
				ModelID: `${customCaptions?.["model"]}`,
				WorkOrder: `${customCaptions?.["serviceWorkOrder"]}`,
				ModelVersionIntervalID: `${customCaptions?.["interval"]}`,
				ModelVersionArrangementID: `${customCaptions?.["arrangement"]}`,
				ModelVersionRoleID: `${customCaptions?.["role"]}`,
				SiteDepartmentID: `${customCaptions?.["department"]}`,
				SiteAssetID: `${customCaptions?.["asset"]}`,
				Status: `Status`,
				ScheduledDate: `Scheduled Date`,
				StartDate: `Start Date`,
				CompletedDate: `Completed Date`,
				NotificationNumber: `Notification Number`,
				ClientName: `Client Name`,
				CompletedEstimatedMinutes: "Completed Estimated Minutes",
				SignatureURL: "Signature URL",
				PercentageComplete: "Percentage Complete",
				PercentageOverTime: "Percentage Over Time",
				MinutesOverTime: "Minutes Overtime",
				StatusName: "Status Name",
			};
		case HistoryCaptions.serviceTask:
			return {
				SiteAppID: `Site Application`,
				ModelVersionStageID: `${customCaptions?.["stage"]}`,
				ModelVersionZoneID: `${customCaptions?.["zone"]}`,
				ModelVersionTaskID: ` ${customCaptions?.["task"]}`,
				ServiceSessionID: `${customCaptions?.["service"]} Session`,
				SiteAssetReferenceID: `${customCaptions?.["assetReference"]}`,
				SiteAsset: `${customCaptions?.["asset"]}`,
				CompletedDate: `Completed Date`,
				ActualMinutes: `Actual Minutes`,
				IsSkipped: `Is Skipped`,
				SkipTaskReasonID: `${customCaptions?.["task"]} Reason`,
				SkipOtherReason: `Skip Other Reason`,
			};

		case HistoryCaptions.siteApp:
			return SiteApp;
		case HistoryCaptions.serviceSession:
			return {
				ServiceID: `${customCaptions?.["service"]}`,
				UserID: `${customCaptions?.["user"]}`,
				StartDate: "Start Date",
				EndDate: "End Date",
				StopReasonID: "Stop Reason",
				OtherReason: "Other Reason",
				SignatureKey: "Signature Key",
			};
		case HistoryCaptions.serviceTaskQuestion:
			return {
				ServiceTaskID: `${customCaptions?.["task"]}`,
				ModelVersionTaskQuestionID: `${customCaptions?.["task"]} Question`,
				Date: "Date",
			};
		case HistoryCaptions.serviceTaskQuestionOption:
			return {
				ServiceTaskQuestionID: `${customCaptions?.["task"]} Question`,
				ModelVersionTaskQuestionOptionID: "Option",
			};
		case HistoryCaptions.serviceSessionQuestion:
			return {
				ServiceSessionID: `${customCaptions?.["service"]} Session`,
				ModelVersionQuestionID: "Question",
				Date: "Date",
			};
		case HistoryCaptions.serviceSessionQuestionOption:
			return {
				ServiceSessionQuestionID: `${customCaptions?.["service"]} Session Question`,
				ModelVersionQuestionOptionID: "Question Option",
			};
		case HistoryCaptions.serviceNote:
			return {
				ServiceID: `${customCaptions?.["service"]}`,
				Note: "Note",
				Date: "Date",
				Name: "Name",
			};
		case HistoryCaptions.serviceMissingPartTool:
			return {
				ServiceID: `${customCaptions?.["service"]}`,
				ServiceSessionID: `${customCaptions?.["service"]} Session`,
				MissingPartToolReasonID: `Missing ${customCaptions?.["part"]} / ${customCaptions?.["tool"]} Reason`,
				ModelVersionTaskPartID: `${APPLICATION?.["PartCC"]}`,
				ModelVersionTaskToolID: `${APPLICATION?.["ToolCC"]}`,
				OtherReason: "Other Reason",
				DateTime: "Date",
			};
		case HistoryCaptions.servicePause:
			return {
				ServiceID: `${customCaptions?.["service"]}`,
				ServiceSessionID: "Session",
				EndDate: "End Date",
				PauseID: `${customCaptions?.["pauseReason"]}`,
				ActionID: `${customCaptions?.["action"]}`,
				NewName: "New Name",
				PauseSubcategoryID: "Pause Subcategory",
				OtherReason: "Other Reason",
				PauseMinutes: "Pause Minutes",
			};
		case HistoryCaptions.serviceStatusChange:
			return {
				ServiceID: `${customCaptions?.["service"]}`,
				Status: "Status",
				ChangeStatusReasonID: "Change Status Reason",
				OtherReason: "Other Reason",
			};
		case HistoryCaptions.feedback:
			return {
				FeedbackClassificationID: `${customCaptions?.["classification"]}`,
				FeedbackPriorityID: `${customCaptions?.["priority"]}`,
				FeedbackStatusID: `${customCaptions?.["feedbackStatus"]}`,
				ModelID: `${APPLICATION?.["ModelCC"]}`,
				ModelVersionStageID: `${customCaptions?.["stage"]}`,
				ModelVersionZoneID: `${customCaptions?.["zone"]}`,
				ModelVersionTaskID: ` ${customCaptions?.["task"]}`,
				AssignUserID: ` Assign ${customCaptions?.["user"]}`,
				AssignPositionID: `${customCaptions?.["position"]}`,
				SiteDepartmentID: `${customCaptions?.["department"]}`,
				SiteAssetID: `${customCaptions?.["asset"]}`,
				ChangeRequired: `${customCaptions?.["changeRequired"]}`,
				Benefit: `${customCaptions?.["benefit"]}`,
			};
		case HistoryCaptions.feedbackNote:
			return {
				FeedbackID: `${customCaptions?.["feedback"]}`,
				Note: "Note",
				Date: "Date",
				Name: "Name",
			};
		case HistoryCaptions.feedbackImage:
			return {
				FeedbackID: `${customCaptions?.["feedback"]}`,
				ImageKey: `Image Key`,
				ThumbnailKey: `Thumbnail Key`,
			};

		case HistoryCaptions.noticeBoard:
			return {
				Name: "Name",
				Description: "Description",
				Link: "Link",
				ExpiryDate: "Expiry Date",
			};
		case HistoryCaptions.modelVersionDepartment:
			return {
				ModelVersionID: ` ${customCaptions?.["model"]} Version`,
				SiteDepartmentID: ` ${customCaptions?.["department"]}`,
			};
		case HistoryCaptions.noticeBoardDepartment:
			return {
				NoticeboardID: ` ${customCaptions?.["tutorial"]}`,
				SiteDepartmentID: ` ${customCaptions?.["department"]}`,
			};
		case HistoryCaptions.modelVersionArrangement:
			return {
				ModelVersionID: ` ${customCaptions?.["model"]} Version`,
				Name: "Name",
			};
		case HistoryCaptions.modelVersionTaskArrangement:
			return {
				ModelVersionTaskID: ` ${customCaptions?.["task"]}`,
				ModelVersionArrangementID: `${customCaptions?.["arrangement"]}`,
			};
		case HistoryCaptions.modelError:
			return {
				ServiceID: `${customCaptions?.["service"]}`,
				UserID: `${customCaptions?.["user"]}`,
				ServiceTaskID: `${customCaptions?.["task"]}`,
				Error: "Error",
			};

		case HistoryCaptions.pause:
			return {
				SiteAppID: "Site Application",
				Name: "Name",
			};

		case HistoryCaptions.pauseSubcategory:
			return {
				PauseID: "Pause",
				Name: "Name",
			};

		case HistoryCaptions.missingPartToolReason:
			return {
				SiteAppID: "Site Application",
				Name: "Name",
			};

		case HistoryCaptions.siteAssetReference:
			return {
				SiteAssetID: `Site [${APPLICATION.AssetCC}]`,
				Name: "Name",
				Description: "Description",
				PlannerGroup: "Planner Group",
				WorkCenter: "Work Center",
			};

		case HistoryCaptions.report:
			return {
				SiteAppID: "Site Application",
				ReportID: "Report",
				Name: "Name",
				Type: "Report Type",
			};

		case HistoryCaptions.skipTaskReason:
			return {
				SiteAppID: "Site Application",
				Name: "Name",
			};

		case HistoryCaptions.stopReason:
			return {
				SiteAppID: "Site Application",
				Name: "Name",
			};

		case HistoryCaptions.changeStatusReason:
			return {
				SiteAppID: "Site Application",
				Name: "Name",
			};

		case HistoryCaptions.modelStatus:
			return {
				SiteAppID: "Site Application",
				Name: "Name",
				Publish: "Publish",
			};

		case HistoryCaptions.modelType:
			return {
				SiteAppID: "Site Application",
				Name: "Name",
				Code: "Code",
			};

		case HistoryCaptions.action:
			return {
				SiteAppID: "Site Application",
				Name: "Name",
			};

		case HistoryCaptions.system:
			return {
				SiteAppID: "Site Application",
				Name: "Name",
				Code: "Code",
			};

		case HistoryCaptions.operatingMode:
			return {
				SiteAppID: "Site Application",
				Name: "Name",
			};

		case HistoryCaptions.lubricant:
			return {
				SiteAppID: "Site Application",
				Name: "Name",
			};

		case HistoryCaptions.role:
			return {
				SiteAppID: "Site Application",
				Name: "Name",
			};

		case HistoryCaptions.position:
			return {
				SiteAppID: "Site Application",
				Name: "Name",
				ModelAccess: `[SiteApp.${APPLICATION.ModelCC}] Access`,
				NoticeboardAccess: `[SiteApp.${APPLICATION.TutorialCC}] Access`,
				FeedbackAccess: `[SiteApp.${APPLICATION.FeedbackCC}] Access`,
				UserAccess: `[SiteApp.${APPLICATION.UserCC}] Access`,
				SettingsAccess: "Settings Access",
				ServiceAccess: `[SiteApp.${APPLICATION.ServiceCC}] Access`,
				DefectAccess: `[SiteApp.${APPLICATION.DefectCC}] Access`,
				AnalyticsAccess: "Analytics Access",
				AssetAccess: `[SiteApp.${APPLICATION.AssetCC}] Access`,
				AllowPublish: "Allow Publish",
				DefaultPage: "Default Page",
			};

		case HistoryCaptions.defectRiskRating:
			return {
				SiteAppID: "Site Application",
				Name: "Name",
				Action: `[SiteApp.${APPLICATION.ActionCC}]`,
			};

		case HistoryCaptions.defectStatus:
			return {
				SiteAppID: "Site Application",
				Name: "Name",
				Type: "Type",
			};

		case HistoryCaptions.defectType:
			return {
				SiteAppID: "Site Application",
				Name: "Name",
			};

		case HistoryCaptions.feedbackClassification:
			return {
				SiteAppID: "Site Application",
				Name: "Name",
			};

		case HistoryCaptions.feedbackPriority:
			return {
				SiteAppID: "Site Application",
				Name: "Name",
			};

		case HistoryCaptions.feedbackStatus:
			return {
				SiteAppID: "Site Application",
				Name: "Name",
				Type: "Type",
			};

		case HistoryCaptions.siteAppKeyContact:
			return {
				SiteAppID: "Site Application",
				UserID: "User",
			};

		default:
			return;
	}
};

export const HistoryProperty = {
	serviceStatus: "serviceStatus",
	questionType: "questionType",
	questionTiming: "questionTiming",
};

export const HistoryPropertyCode = (from, customCaptions) => {
	switch (from) {
		case HistoryProperty.questionTiming:
			return {
				B: `Beginning of ${customCaptions["service"]}`,
				E: `End of ${customCaptions["service"]}`,
				S: `Beginning of ${customCaptions["stage"]}`,
				Z: `Beginning of ${customCaptions["zone"]}`,
			};
		case HistoryProperty.questionType:
			return {
				S: "Short Text",
				B: "Boolean",
				N: "Number",
				L: "Long Text",
				T: "Time",
				D: "Date",
				O: "Dropdown List",
				C: "List",
			};
		case HistoryProperty.serviceStatus:
			return {
				T: `Stopped`,
				I: "In Progress",
				C: "Complete",
				X: "Cancelled",
				H: "Checked Out",
				M: "Incomplete",
				P: "Completed by Paper",
				S: "Scheduled",
			};
		default:
			return;
	}
};

export const DETAILS = "Details";
export const ASSETS = "Assets";
export const DEPARTMENTS = "Departments";

export const siteScreenNavigation = [
	{ name: DETAILS, url: `/${siteDetailPath}` },
	{ name: ASSETS, url: siteAssetPath },
	{ name: DEPARTMENTS, url: siteDepartmentPath },
];

export const modelScreenNavigation = ({
	assests,
	stages,
	zones,
	intervals,
	roles,
	questions,
	tasks,
}) => [
	{ name: "Details", url: modelDetail },
	{ name: `Assests(${assests})`, url: modelAssest },
	{ name: `Stages(${stages})`, url: modelStages },
	{ name: `Zones(${zones})`, url: modelZones },
	{ name: `Intervals(${intervals})`, url: modelIntervals },
	{ name: `Roles(${roles})`, url: modelRoles },
	{ name: `Questions(${questions})`, url: modelQuestions },
	{ name: `Tasks(${tasks})`, url: modelTask },
	{ name: "Service Layout", url: modelServiceLayout },
];

export const defectStatusTypes = [
	{ label: "Outstanding", value: "O" },
	{ label: "Complete", value: "C" },
];

export const positionAccessTypes = {
	F: "Full",
	E: "Edit",
	R: "Read-Only",
	N: "None",
};

export const NoReadOnly = ["F", "E"];
export const AccessTypes = {
	Full: "F",
	Edit: "E",
	"Read-Only": "R",
	None: "N",
};

export const DefaultPageOptions = (cc) => {
	return {
		6: "Analytics",
		5: cc?.defectPlural ?? "Defects",
		2: cc?.feedbackPlural ?? "Feedback",
		0: cc?.modelPlural ?? "Models",
		1: cc?.tutorialPlural ?? "Noticeboards",
		4: cc?.servicePlural ?? "Services",
		3: cc?.userPlural ?? "Users",
	};
};

export const defaultRedirect = {
	6: analysisPath,
	5: `/app/${defectsPath}`,
	2: `/app/${feedbackPath}`,
	0: `/app/${modelsPath}`,
	1: "/app/noticeboards",
	4: `/app/${servicesPath}`,
	3: `/app/{usersPath}`,
};

export const pageSize = {
	SMALL_SIZE: 15,
	MEDIUM_SIZE: 20,
	LARGE_SIZE: 25,
};

export const NotificationAlerts = {
	success: "#24BA78",
	error: "#E31212",
	info: "#307AD6",
	warning: "#ED8738",
};

export const workbookFields = (customCaptions) => [
	{ id: "1", name: "purpose", label: `${customCaptions.purpose}` },
	{ id: "2", name: "procedure", label: `${customCaptions.procedure}` },
	{
		id: "3",
		name: "possibleHazards",
		label: `${customCaptions.possibleHazardsPlural}`,
	},
	{
		id: "4",
		name: "additionalPPE",
		label: `${customCaptions.additionalPPE}`,
	},
	{
		id: "5",
		name: "specification",
		label: `${customCaptions.specification}`,
	},
	{
		id: "6",
		name: "contaminationControl",
		label: `${customCaptions.contaminationControlsPlural}`,
	},
	{
		id: "7",
		name: "otherInformation",
		label: `${customCaptions.otherInformation}`,
	},
	{
		id: "8",
		name: "correctiveActions",
		label: `${customCaptions.correctiveActionsPlural}`,
	},
	{
		id: "9",
		name: "isolations",
		label: `${customCaptions.isolationsPlural}`,
	},
	{ id: "10", name: "controls", label: `${customCaptions.controlsPlural}` },
	{ id: "11", name: "customField1", label: `${customCaptions.customField1}` },
	{ id: "12", name: "customField2", label: `${customCaptions.customField2}` },
	{ id: "13", name: "customField3", label: `${customCaptions.customField3}` },
];

export const DROPDOWN_TOP_OFFSET = 30;
export const DROPDOWN_LEFT_OFFSET = 30;
export const DROPDOWN_RIGHT_OFFSET = 30;

export const statusOptions = [
	{ id: "X", name: "Cancelled", groupBy: "Complete" },
	{ id: "C", name: "Complete", groupBy: "Complete" },
	{ id: "P", name: "Completed by Paper", groupBy: "Complete" },
	{ id: "H", name: "Checked Out", groupBy: "Not Complete" },
	{ id: "I", name: "In Progress", groupBy: "Not Complete" },
	{ id: "N", name: "Incomplete", groupBy: "Complete" },
	{ id: "S", name: "Scheduled", groupBy: "Not Complete" },
	{ id: "T", name: "Stopped", groupBy: "Not Complete" },
];

export const statusTypeClassification = { 1: "C", 2: "O" };

// defects storage
export const DEFECTS_STORAGE_STATUS = "defects-status";
export const DEFECTS_STORAGE_DEPARTMENT = "defects-department";
export const DEFECTS_STORAGE_TIMEFRAME = "defects-timeFrame";

// feedback storage
export const FEEDBACK_STORAGE_STATUS = "feedback-status";
export const FEEDBACK_STORAGE_DEPARTMENT = "feedback-department";
export const FEEDBACK_STORAGE_TIMEFRAME = "feedback-timeFrame";
export const FEEDBACK_STORAGE_MY_FEEDBACK = "feedback-myfeedback";

//service storage
export const SERVICE_STORAGE_STATUS = "service-status";
export const SERVICE_STORAGE_DEPARTMENT = "service-department";
export const SERVICE_STORAGE_TIMEFRAME = "service-timeFrame";

//model storage
export const MODEL_STORAGE_STATUS = "model-status";
export const MODEL_STORAGE_DEPARTMENT = "model-department";

export const REMOVE_SESSIONS = [
	SERVICE_STORAGE_TIMEFRAME,
	SERVICE_STORAGE_STATUS,
	SERVICE_STORAGE_DEPARTMENT,
	MODEL_STORAGE_STATUS,
	MODEL_STORAGE_DEPARTMENT,
	FEEDBACK_STORAGE_TIMEFRAME,
	FEEDBACK_STORAGE_STATUS,
	FEEDBACK_STORAGE_DEPARTMENT,
	DEFECTS_STORAGE_DEPARTMENT,
	DEFECTS_STORAGE_TIMEFRAME,
	DEFECTS_STORAGE_STATUS,
];

export const Months = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"June",
	"July",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec",
];

//license type
export const LicenceType = (licenseLevel) => {
	switch (licenseLevel) {
		case "A":
			return "Application License";
		case "S":
			return "Site License";
		case "C":
			return "Client License";
		default:
			return "Unknown License";
	}
};

// file upload encoding notes
export const UPLOAD_CSV_NOTE =
	"NOTE: Please ensure that your CSV file is UTF-8 Encoded before uploading.";
