export default {
	analyticsAccess: "analyticsAccess",
	analysisAccess: "analysisAccess",
	defectAccess: "defectAccess",
	defectExportAccess: "defectExportAccess",
	feedbackAccess: "feedbackAccess",
	modelAccess: "modelAccess",
	noticeboardAccess: "noticeboardAccess",
	serviceAccess: "serviceAccess",
	settingsAccess: "settingsAccess",
	userAccess: "userAccess",
};
