import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { isoDateWithoutTimeZone } from "helpers/utils";
import React from "react";
import { makeStyles } from "tss-react/mui";
import Icon from "components/Elements/Icon";

const useStyles = makeStyles()((theme) => ({
	headerText: {
		fontWeight: 600,
		fontSize: 20,
		marginBottom: "5px",
	},
	stageheader: {
		fontWeight: 800,
		fontSize: 20,
		marginBottom: "5px",
	},
	stageZoneQuestionHeader: {
		fontWeight: 800,
		fontSize: 18,
		marginBottom: "5px",
	},
	tableHead: {
		backgroundColor: "#D2D2D9",
		display: "table-row-group",
		// border: "1px solid",
	},
	noDataTableRow: {
		borderBottom: "none !important",
	},
	// tableBody: {
	// 	border: "1px solid",
	// },
}));

function dynamicWidth(i) {
	if (i === 0) {
		return {
			width: "40vw",
		};
	}

	if (i === 1) {
		return { width: "30vw" };
	}
}

const ServiceStages = ({
	tasks,
	formatQuestion,
	customCaptions,
	completedBy = "",
	showSkipped,
	isIncomplete,
}) => {
	const { classes, cx } = useStyles();

	const questionsHeader = [
		"Question",
		"Response",
		"Completed By",
		"Completion Time",
	];

	const renderStageQuestions = (questions, isStageQuestions = false) => {
		if (questions?.length > 0) {
			return (
				<>
					<Typography className={classes.stageZoneQuestionHeader}>
						{isStageQuestions ? "Stage Questions" : "Zone Questions"}
					</Typography>
					<Table className={classes.tableContainer}>
						<TableHead className={classes.tableHead}>
							<TableRow>
								{questionsHeader.map((data, i) => (
									<TableCell key={data} style={dynamicWidth(i)}>
										{data}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{questions &&
								formatQuestion(questions).map((data, index) => (
									<TableRow key={index}>
										<TableCell>{data?.caption}</TableCell>
										<TableCell>{data?.response}</TableCell>
										<TableCell>{completedBy}</TableCell>
										<TableCell>{data.date}</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</>
			);
		}
		return null;
	};

	const renderZoneTaskQuestion = (data) => {
		return data?.tasks?.length ? (
			<Table
				className={classes.tableContainer}
				style={{ marginBottom: "10px" }}
			>
				<TableHead className={classes.tableHead}>
					<TableRow>
						{[customCaptions?.task, "", "Completed By", "Completion Time"].map(
							(header, i) => (
								<TableCell key={header} style={dynamicWidth(i)}>
									{i === 0 && (
										<span style={{ opacity: 0, marginLeft: "20px" }}>
											<Icon name="SafteryCritical" />
										</span>
									)}
									{header}
								</TableCell>
							)
						)}
					</TableRow>
				</TableHead>
				<TableBody className={classes.tableBody}>
					{data?.tasks
						?.map((zone) => {
							return {
								...zone,
								completedDate: zone.completedDate
									? zone.completedDate
									: showSkipped
									? "Skipped"
									: "",
								actionName: (
									<span style={{ marginRight: "10px" }}>
										{zone.safetyCritical ? (
											<Icon name="SafteryCritical" />
										) : (
											<span style={{ opacity: 0 }}>
												<Icon name="SafteryCritical" />
											</span>
										)}
										<span style={{ marginLeft: "20px" }}>
											{zone.actionName} {zone.taskName}
										</span>
									</span>
								),
								completedBy,
							};
						})
						.map((t) => (
							<>
								<TableRow>
									{["actionName","", "completedBy", "completedDate"].map(
										(column, i) => (
											<TableCell key={column}>{t[column]}</TableCell>
										)
									)}
								</TableRow>

								{t?.taskQuestion &&
									formatQuestion(t?.taskQuestion)?.map((q) => (
										<TableRow
											style={{
												backgroundColor: "rgba(214, 212, 212,0.6)",
											}}
										>
											<TableCell>
											
												<span
													style={{
														opacity: 0,
														marginLeft: "10px",
													}}
												>
													<Icon name="SafteryCritical" />
												</span>
												<span style={{ marginLeft: "12px" }}>{q?.caption}</span>
											</TableCell>
											{
												<TableCell colSpan={3}>
													<div
														style={{
															border: q?.response ? "1px solid" : "none",
															width: "100%",
															minHeight: "30px",
															padding: "5px",
														}}
													>
														{q?.response ? q.response : ""}
													</div>
												</TableCell>
											}
										</TableRow>
									))}
							</>
						))}
				</TableBody>
			</Table>
		) : null;
	};

	return (
		<>
			{tasks?.map((task) => (
				<>
					<Typography className={classes.stageheader}>
						{task?.stagename || task?.name}
					</Typography>

					{renderStageQuestions(task.questions, true)}

					{isIncomplete ? (
						task.stageData.map((stage) => (
							<>
								<Typography className={classes.headerText}>
									{stage?.zonename}
								</Typography>
								{renderZoneTaskQuestion(stage)}
							</>
						))
					) : (
						<>
							{renderZoneTaskQuestion(task)}
							{task?.zones?.map((zone) => {
								return zone.tasks.length ? (
									<>
										<Typography className={classes.headerText}>
											{zone?.name}
										</Typography>
										{renderStageQuestions(zone.questions, false)}
										{renderZoneTaskQuestion(zone)}
									</>
								) : null;
							})}
						</>
					)}
				</>
			))}
		</>
	);
};

export default ServiceStages;
