//history custom captions
export const APPLICATION = {
	Name: "Name",
	Purpose: "Purpose",
	LogoKey: "Logo Key",
	LogoFilename: "Logo Filename",
	SmallLogoKey: "Small Logo Key",
	SmallLogoFilename: "Small Logo Filename",
	MobileSmallWhiteAppLogoKey: "Mobile Small White App Logo Key",
	MobileWhiteAppLogoKey: "Mobile White App Logo Key",
	NavigationLogoKey: "Navigation Logo Key",
	WatermarkKey: "Watermark Key",
	IsLogoTrademarked: "Logo Trademarked",
	URL: "URL",
	FeedbackClassificationCC: "Feedback Classification Custom Caption",
	Color: "Color",
	AlertColor: "Alert Color",
	IsActive: "Is Active",
	ModelTemplateCC: "Model Template Custom Caption",
	ModelTemplatePluralCC: "Model Template Plural Custom Caption",
	LocationCC: "Location Custom Caption",
	LocationPluralCC: "Location Plural Custom Caption",
	StageCC: "Stage Custom Caption",
	StagePluralCC: "Stage Plural Custom Caption",
	ZoneCC: "Zone Custom Caption",
	ZonePluralCC: "Zone Plural Custom Caption",
	IntervalCC: "Interval Custom Caption",
	IntervalPluralCC: "Interval Plural Custom Caption",
	TaskListNoCC: "Task List No Custom Caption",
	TaskListNoPluralCC: "Task List No Plural Custom Caption",
	QuestionCC: "Question Custom Caption",
	QuestionPluralCC: "Question Plural Custom Caption",
	TaskCC: "Task Custom Caption",
	TaskPluralCC: "Task Plural Custom Caption",
	ActionRequiredCC: "Action Required Custom Caption",
	ActionRequiredPluralCC: "Action Required Plural Custom Caption",
	OperatingModeCC: "Operating Mode Custom Caption",
	OperatingModePluralCC: "Operating Mode Plural Custom Caption",
	SystemCC: "System Custom Caption",
	SystemPluralCC: "System Plural Custom Caption",
	SafetyCriticalCC: "Safety Critical Custom Caption",
	SafetyCriticalPluralCC: "Safety Critical Plural Custom Caption",
	PartCC: "Part Custom Caption",
	PartPluralCC: "Part Plural Custom Caption",
	PartQuantityCC: "Part Quantity Custom Caption",
	PartQuantityPluralCC: "Part Quantity Plural Custom Caption",
	PartNameCC: "Part Name Custom Caption",
	PartNamePluralCC: "Part Name Plural Custom Caption",
	PartDescriptionCC: "Part Description Custom Caption",
	PartDescriptionPluralCC: "Part Description Plural Custom Caption",
	PartStockNumberCC: "Part Stock Number Custom Caption",
	PartStockNumberPluralCC: "Part Stock Number Plural Custom Caption",
	ToolCC: "Tool Custom Caption",
	ToolPluralCC: "Tool Plural Custom Caption",
	ToolQuantityCC: "Tool Quantity Custom Caption",
	ToolQuantityPluralCC: "Tool Quantity Plural Custom Caption",
	ToolDescriptionCC: "Tool Description Custom Caption",
	ToolDescriptionPluralCC: "Tool Description Plural Custom Caption",
	LubricantCC: "Lubricant Custom Caption",
	LubricantPluralCC: "Lubricant Plural Custom Caption",
	WorkbookCC: "Workbook Custom Caption",
	WorkbookPluralCC: "Workbook Plural Custom Caption",
	PurposeCC: "Purpose Custom Caption",
	PurposePluralCC: "Purpose Plural Custom Caption",
	ProcedureCC: "Procedure Custom Caption",
	ProcedurePluralCC: "Procedure Plural Custom Caption",
	PossibleHazardsCC: "Possible Hazards Custom Caption",
	PossibleHazardsPluralCC: "Possible Hazards Plural Custom Caption",
	AdditionalPPECC: "Additional PPE Custom Caption",
	AdditionalPPEPluralCC: "Additional PPE Plural Custom Caption",
	SpecificationCC: "Specification Custom Caption",
	SpecificationPluralCC: "Specification Plural Custom Caption",
	ContaminationControlsCC: "Contamination Controls Custom Caption",
	ContaminationControlsPluralCC: "Contamination Controls Plural Custom Caption",
	OtherInformationCC: "Other Information Custom Caption",
	OtherInformationPluralCC: "Other Information Plural Custom Caption",
	CorrectiveActionsCC: "Corrective Actions Custom Caption",
	CorrectiveActionsPluralCC: "Corrective Actions Plural Custom Caption",
	IsolationsCC: "Isolations Custom Caption",
	IsolationsPluralCC: "Isolations Plural Custom Caption",
	ControlsCC: "Controls Custom Caption",
	ControlsPluralCC: "Controls Plural Custom Caption",
	CustomField1CC: "Custom Field 1 Custom Caption",
	CustomField1PluralCC: "Custom Field 1 Plural Custom Caption",
	CustomField2CC: "Custom Field 2 Custom Caption",
	CustomField2PluralCC: "Custom Field 2 Plural Custom Caption",
	CustomField3CC: "Custom Field 3 Custom Caption",
	CustomField3PluralCC: "Custom Field 3 Plural Custom Caption",
	MakeCC: "Make Custom Caption",
	MakePluralCC: "Make Plural Custom Caption",
	ModelCC: "Model Custom Caption",
	ModelPluralCC: "Model Plural Custom Caption",
	ModelTypeCC: "Model Type Custom Caption",
	ModelTypePluralCC: "Model Type Plural Custom Caption",
	DeveloperNameCC: "Developer Name Custom Caption",
	DeveloperNamePluralCC: "Developer Name Plural Custom Caption",
	RoleCC: "Role Custom Caption",
	RolePluralCC: "Role Plural Custom Caption",
	TutorialCC: "Tutorial Custom Caption",
	TutorialPluralCC: "Tutorial Plural Custom Caption",
	FeedbackCC: "Feedback Custom Caption",
	FeedbackPluralCC: "Feedback Plural Custom Caption",
	ChangeRequiredCC: "Change Required Custom Caption",
	ChangeRequiredPluralCC: "Change Required Plural Custom Caption",
	BenefitCC: "Benefit Custom Caption",
	BenefitPluralCC: "Benefit Plural Custom Caption",
	ClassificationCC: "Classification Custom Caption",
	ClassificationPluralCC: "Classification Plural Custom Caption",
	PriorityCC: "Priority Custom Caption",
	PriorityPluralCC: "Priority Plural Custom Caption",
	FeedbackStatusCC: "Feedback Status Custom Caption",
	FeedbackStatusPluralCC: "Feedback Status Plural Custom Caption",
	UserCC: "User Custom Caption",
	UserPluralCC: "User Plural Custom Caption",
	UserReferenceCC: "User Reference Custom Caption",
	UserReferencePluralCC: "User Reference Plural Custom Caption",
	PositionCC: "Position Custom Caption",
	PositionPluralCC: "Position Plural Custom Caption",
	ServiceCC: "Service Custom Caption",
	ServicePluralCC: "Service Plural Custom Caption",
	ServiceWorkOrderCC: "Service Work Order Custom Caption",
	ServiceWorkOrderPluralCC: "Service Work Order Plural Custom Caption",
	PauseReasonCC: "Pause Reason Custom Caption",
	PauseReasonPluralCC: "Pause Reason Plural Custom Caption",
	StopReasonCC: "Stop Reason Custom Caption",
	StopReasonPluralCC: "Stop Reason Plural Custom Caption",
	SkipReasonCC: "Skip Reason Custom Caption",
	SkipReasonPluralCC: "Skip Reason Plural Custom Caption",
	DefectCC: "Defect Custom Caption",
	DefectRiskRatingCC: "Defect Risk Rating Custom Caption",
	DefectPluralCC: "Defect Plural Custom Caption",
	DefectWorkOrderCC: "Defect Work Order Custom Caption",
	DefectWorkOrderPluralCC: "Defect Work Order Plural Custom Caption",
	DefectStatusCC: "Defect Status Custom Caption",
	DefectStatusPluralCC: "Defect Status Plural Custom Caption",
	DefectTypeCC: "Defect Type Custom Caption",
	DefectTypePluralCC: "Defect Type Plural Custom Caption",
	RiskRatingCC: "Risk Rating Custom Caption",
	RiskRatingPluralCC: "Risk Rating Plural Custom Caption",
	RiskRatingActionCC: "Risk Rating Action Custom Caption",
	RiskRatingActionPluralCC: "Risk Rating Action Plural Custom Caption",
	RiskRatingMatrixCC: "Risk Rating Matrix Custom Caption",
	RiskRatingMatrixPluralCC: "Risk Rating Matrix Plural Custom Caption",
	DepartmentCC: "Department Custom Caption",
	DepartmentPluralCC: "Department Plural Custom Caption",
	AssetCC: "Asset Custom Caption",
	AssetPluralCC: "Asset Plural Custom Caption",
	AssetReferenceCC: "Asset Reference Custom Caption",
	AssetReferencePluralCC: "Asset Reference Plural Custom Caption",
	AssetPlannerGroupCC: "Asset Planner Group Custom Caption",
	AssetPlannerGroupPluralCC: "Asset Planner Group Plural Custom Caption",
	AssetMainWorkCenterCC: "Asset Main Work Center Custom Caption",
	AssetMainWorkCenterPluralCC: "Asset Main Work Center Plural Custom Caption",
	SerialNumberRangeCC: "Serial Number Range Custom Caption",
	SerialNumberRangePluralCC: "Serial Number Range Plural Custom Caption",
	PermitCC: "Permit Custom Caption",
	PermitPluralCC: "Permit Plural Custom Caption",
	ArrangementCC: "Arrangement Custom Caption",
	ArrangementPluralCC: "Arrangement Plural Custom Caption",
	NotificationNumberCC: "Notification Number Custom Caption",
	NotificationNumberPluralCC: "Notification Number Plural Custom Caption",
	AllowIndividualAssetModels: "Module - Allow Individual Asset Models",
	AllowFacilityBasedModels: "Module - Allow Facility Based Models",
	ShowLubricants: "Module - Show Lubricants",
	ShowParts: "Module - Show Parts",
	ShowOperatingMode: "Module - Show Operating Modes",
	ShowSystem: "Module - Show Systems",
	ShowDefectParts: "Module - Show Defect Parts",
	ShowSerialNumberRange: "Module - Show Serial Number Range",
	ShowServiceClientName: "Module - Show Service Client Name",
	ShowArrangements: "Module - Show Arrangements",
	AllowRegisterAssetsForServices:
		"Module - Allow Registration of Assets for Services",
	DefaultDefectStatusID: "Default Defect Status",
	DefaultFeedbackClassificationID: "Default Feedback Classification",
	DefaultFeedbackPriorityID: "Default Feedback Priority",
	DefaultFeedbackStatusID: "Default Feedback Status",
	DefaultModelStatusID: "Default Model Status",
	DefaultOperatingModeID: "Default Operating Mode",
	DefaultSafetyCriticalDefectRiskRatingID:
		"Default Safety Critical Defect Risk Rating",
};

//site app - history captions
export const SITE_APP = {
	SiteID: "Site",
	ApplicationID: "Application",
	IsActive: "Is Active",
	DefectRiskRatingKey: `${APPLICATION.DefectRiskRatingCC} Key`,
	LicenseType: "License Type",
	Licenses: "License Count",
	ShowServiceUserConfirmation: `${APPLICATION.ServiceCC} - Show User Confirmation`,
	ShowServiceClientName: `${APPLICATION.ServiceCC} - Show Client Name`,
	UserConfirmationMessage: `${APPLICATION.ServiceCC} - User Confirmation Message`,
	RaisingDefectCopiesTaskName: `Raising ${APPLICATION.DefectCC} Copies`,
	TaskName: `${APPLICATION.TaskCC} Name`,
	DefaultDefectStatusID: `Default ${APPLICATION.DefectStatusCC}`,
	DefaultFeedbackClassificationID: `Default ${APPLICATION.FeedbackClassificationCC}`,
	DefaultFeedbackPriorityID: `Default ${APPLICATION.FeedbackPriorityCC}`,
	DefaultFeedbackStatusID: `Default ${APPLICATION.FeedbackStatusCC}`,
	DefaultModelStatusID: `Default ${APPLICATION.ModelStatusCC}`,
	DefaultOperatingModeID: `Default ${APPLICATION.OperatingModeCC}`,
	DefaultSafetyCriticalDefectRiskRatingID: `Default ${APPLICATION.SafetyCriticalCC}`,
	DefectRiskRatingCC: `${APPLICATION.DefectRiskRatingCC}`,
	ModelTemplateCC: `${APPLICATION.ModelTemplateCC}`,
	ModelTemplatePluralCC: `${APPLICATION.ModelTemplatePluralCC}`,
	LocationCC: `${APPLICATION.LocationCC}`,
	LocationPluralCC: `${APPLICATION.LocationPluralCC}`,
	StageCC: `${APPLICATION.StageCC}`,
	StagePluralCC: `${APPLICATION.StagePluralCC}`,
	ZoneCC: `${APPLICATION.ZoneCC}`,
	ZonePluralCC: `${APPLICATION.ZonePluralCC}`,
	IntervalCC: `${APPLICATION.IntervalCC}`,
	IntervalPluralCC: `${APPLICATION.IntervalPluralCC}`,
	TaskListNoCC: `${APPLICATION.TaskListNoCC}`,
	TaskListNoPluralCC: `${APPLICATION.TaskListNoPluralCC}`,
	QuestionCC: `${APPLICATION.QuestionCC}`,
	QuestionPluralCC: `${APPLICATION.QuestionPluralCC}`,
	TaskCC: `${APPLICATION.TaskCC}`,
	TaskPluralCC: `${APPLICATION.TaskPluralCC}`,
	ActionRequiredCC: `${APPLICATION.ActionRequiredCC}`,
	ActionRequiredPluralCC: `${APPLICATION.ActionRequiredPluralCC}`,
	OperatingModeCC: `${APPLICATION.OperatingModeCC}`,
	OperatingModePluralCC: `${APPLICATION.OperatingModePluralCC}`,
	SystemCC: `${APPLICATION.SystemCC}`,
	SystemPluralCC: `${APPLICATION.SystemPluralCC}`,
	SafetyCriticalCC: `${APPLICATION.SafetyCriticalCC}`,
	SafetyCriticalPluralCC: `${APPLICATION.SafetyCriticalPluralCC}`,
	PartCC: `${APPLICATION.PartCC}`,
	PartPluralCC: `${APPLICATION.PartPluralCC}`,
	PartQuantityCC: `${APPLICATION.PartQuantityCC}`,
	PartQuantityPluralCC: `${APPLICATION.PartQuantityPluralCC}`,
	PartNameCC: `${APPLICATION.PartNameCC}`,
	PartNamePluralCC: `${APPLICATION.PartNamePluralCC}`,
	PartDescriptionCC: `${APPLICATION.PartDescriptionCC}`,
	PartDescriptionPluralCC: `${APPLICATION.PartDescriptionPluralCC}`,
	PartStockNumberCC: `${APPLICATION.PartStockNumberCC}`,
	PartStockNumberPluralCC: `${APPLICATION.PartStockNumberPluralCC}`,
	ToolCC: `${APPLICATION.ToolCC}`,
	ToolPluralCC: `${APPLICATION.ToolPluralCC}`,
	ToolQuantityCC: `${APPLICATION.ToolQuantityCC}`,
	ToolQuantityPluralCC: `${APPLICATION.ToolQuantityPluralCC}`,
	ToolDescriptionCC: `${APPLICATION.ToolDescriptionCC}`,
	ToolDescriptionPluralCC: `${APPLICATION.ToolDescriptionPluralCC}`,
	LubricantCC: `${APPLICATION.LubricantCC}`,
	LubricantPluralCC: `${APPLICATION.LubricantPluralCC}`,
	WorkbookCC: `${APPLICATION.WorkbookCC}`,
	WorkbookPluralCC: `${APPLICATION.WorkbookPluralCC}`,
	PurposeCC: `${APPLICATION.PurposeCC}`,
	PurposePluralCC: `${APPLICATION.PurposePluralCC}`,
	ProcedureCC: `${APPLICATION.ProcedureCC}`,
	ProcedurePluralCC: `${APPLICATION.ProcedurePluralCC}`,
	PossibleHazardsCC: `${APPLICATION.PossibleHazardsCC}`,
	PossibleHazardsPluralCC: `${APPLICATION.PossibleHazardsPluralCC}`,
	AdditionalPPECC: `${APPLICATION.AdditionalPPECC}`,
	AdditionalPPEPluralCC: `${APPLICATION.AdditionalPPEPluralCC}`,
	SpecificationCC: `${APPLICATION.SpecificationCC}`,
	SpecificationPluralCC: `${APPLICATION.SpecificationPluralCC}`,
	ContaminationControlsCC: `${APPLICATION.ContaminationControlsCC}`,
	ContaminationControlsPluralCC: `${APPLICATION.ContaminationControlsPluralCC}`,
	OtherInformationCC: `${APPLICATION.OtherInformationCC}`,
	OtherInformationPluralCC: `${APPLICATION.OtherInformationPluralCC}`,
	CorrectiveActionsCC: `${APPLICATION.CorrectiveActionsCC}`,
	CorrectiveActionsPluralCC: `${APPLICATION.CorrectiveActionsPluralCC}`,
	IsolationsCC: `${APPLICATION.IsolationsCC}`,
	IsolationsPluralCC: `${APPLICATION.IsolationsPluralCC}`,
	ControlsCC: `${APPLICATION.ControlsCC}`,
	ControlsPluralCC: `${APPLICATION.ControlsPluralCC}`,
	CustomField1CC: `${APPLICATION.CustomField1CC}`,
	CustomField1PluralCC: `${APPLICATION.CustomField1PluralCC}`,
	CustomField2CC: `${APPLICATION.CustomField2CC}`,
	CustomField2PluralCC: `${APPLICATION.CustomField2PluralCC}`,
	CustomField3CC: `${APPLICATION.CustomField3CC}`,
	CustomField3PluralCC: `${APPLICATION.CustomField3PluralCC}`,
	MakeCC: `${APPLICATION.MakeCC}`,
	MakePluralCC: `${APPLICATION.MakePluralCC}`,
	ModelCC: `${APPLICATION.ModelCC}`,
	ModelPluralCC: `${APPLICATION.ModelPluralCC}`,
	ModelTypeCC: `${APPLICATION.ModelTypeCC}`,
	ModelTypePluralCC: `${APPLICATION.ModelTypePluralCC}`,
	DeveloperNameCC: `${APPLICATION.DeveloperNameCC}`,
	DeveloperNamePluralCC: `${APPLICATION.DeveloperNamePluralCC}`,
	RoleCC: `${APPLICATION.RoleCC}`,
	RolePluralCC: `${APPLICATION.RolePluralCC}`,
	TutorialCC: `${APPLICATION.TutorialCC}`,
	TutorialPluralCC: `${APPLICATION.TutorialPluralCC}`,
	FeedbackCC: `${APPLICATION.FeedbackCC}`,
	FeedbackPluralCC: `${APPLICATION.FeedbackPluralCC}`,
	ChangeRequiredCC: `${APPLICATION.ChangeRequiredCC}`,
	ChangeRequiredPluralCC: `${APPLICATION.ChangeRequiredPluralCC}`,
	BenefitCC: `${APPLICATION.BenefitCC}`,
	BenefitPluralCC: `${APPLICATION.BenefitPluralCC}`,
	ClassificationCC: `${APPLICATION.ClassificationCC}`,
	ClassificationPluralCC: `${APPLICATION.ClassificationPluralCC}`,
	PriorityCC: `${APPLICATION.PriorityCC}`,
	PriorityPluralCC: `${APPLICATION.PriorityPluralCC}`,
	FeedbackStatusCC: `${APPLICATION.FeedbackStatusCC}`,
	FeedbackStatusPluralCC: `${APPLICATION.FeedbackStatusPluralCC}`,
	UserCC: `${APPLICATION.UserCC}`,
	UserPluralCC: `${APPLICATION.UserPluralCC}`,
	UserReferenceCC: `${APPLICATION.UserReferenceCC}`,
	UserReferencePluralCC: `${APPLICATION.UserReferencePluralCC}`,
	PositionCC: `${APPLICATION.PositionCC}`,
	PositionPluralCC: `${APPLICATION.PositionPluralCC}`,
	ServiceCC: `${APPLICATION.ServiceCC}`,
	ServicePluralCC: `${APPLICATION.ServicePluralCC}`,
	ServiceWorkOrderCC: `${APPLICATION.ServiceWorkOrderCC}`,
	ServiceWorkOrderPluralCC: `${APPLICATION.ServiceWorkOrderPluralCC}`,
	PauseReasonCC: `${APPLICATION.PauseReasonCC}`,
	PauseReasonPluralCC: `${APPLICATION.PauseReasonPluralCC}`,
	StopReasonCC: `${APPLICATION.StopReasonCC}`,
	StopReasonPluralCC: `${APPLICATION.StopReasonPluralCC}`,
	SkipReasonCC: `${APPLICATION.SkipReasonCC}`,
	SkipReasonPluralCC: `${APPLICATION.SkipReasonPluralCC}`,
	DefectCC: `${APPLICATION.DefectCC}`,
	DefectPluralCC: `${APPLICATION.DefectPluralCC}`,
	DefectWorkOrderCC: `${APPLICATION.DefectWorkOrderCC}`,
	DefectWorkOrderPluralCC: `${APPLICATION.DefectWorkOrderPluralCC}`,
	DefectStatusCC: `${APPLICATION.DefectStatusCC}`,
	DefectStatusPluralCC: `${APPLICATION.DefectStatusPluralCC}`,
	DefectTypeCC: `${APPLICATION.DefectTypeCC}`,
	DefectTypePluralCC: `${APPLICATION.DefectTypePluralCC}`,
	RiskRatingCC: `${APPLICATION.RiskRatingCC}`,
	RiskRatingPluralCC: `${APPLICATION.RiskRatingPluralCC}`,
	RiskRatingActionCC: `${APPLICATION.RiskRatingActionCC}`,
	RiskRatingActionPluralCC: `${APPLICATION.RiskRatingActionPluralCC}`,
	RiskRatingMatrixCC: `${APPLICATION.RiskRatingMatrixCC}`,
	RiskRatingMatrixPluralCC: `${APPLICATION.RiskRatingMatrixPluralCC}`,
	DepartmentCC: `${APPLICATION.DepartmentCC}`,
	DepartmentPluralCC: `${APPLICATION.DepartmentPluralCC}`,
	AssetCC: `${APPLICATION.AssetCC}`,
	AssetPluralCC: `${APPLICATION.AssetPluralCC}`,
	AssetReferenceCC: `${APPLICATION.AssetReferenceCC}`,
	AssetReferencePluralCC: `${APPLICATION.AssetReferencePluralCC}`,
	AssetPlannerGroupCC: `${APPLICATION.AssetPlannerGroupCC}`,
	AssetPlannerGroupPluralCC: `${APPLICATION.AssetPlannerGroupPluralCC}`,
	AssetMainWorkCenterCC: `${APPLICATION.AssetMainWorkCenterCC}`,
	AssetMainWorkCenterPluralCC: `${APPLICATION.AssetMainWorkCenterPluralCC}`,
	SerialNumberRangeCC: `${APPLICATION.SerialNumberRangeCC}`,
	SerialNumberRangePluralCC: `${APPLICATION.SerialNumberRangePluralCC}`,
	PermitCC: `${APPLICATION.PermitCC}`,
	PermitPluralCC: `${APPLICATION.PermitPluralCC}`,
	ArrangementCC: `${APPLICATION.ArrangementCC}`,
	ArrangementPluralCC: `${APPLICATION.ArrangementPluralCC}`,
	NotificationNumberCC: `${APPLICATION.NotificationNumberCC}`,
	NotificationNumberPluralCC: `${APPLICATION.NotificationNumberPluralCC}`,
};

export const HISTORY_CAPTIONS = {
	siteApp: "applicationEntity",
	applicationEntity: "applicationEntity",
	applicationModelStatus: "applicationModelStatus",
	applicationPause: "applicationPause",
	applicationAction: "applicationAction",
	applicationSystem: "applicationSystem",
	applicationOperatingMode: "applicationOperatingMode",
	applicationModelType: "applicationModelType ",
	applicationRole: "applicationRole",
	applicationStopReason: "applicationStopReason",
	applicationDefectRiskRating: "applicationDefectRiskRating",
	applicationDefectStatus: "applicationDefectStatus",
	applicationFeedbackStatus: "applicationFeedbackStatus",
	applicationFeedbackPriority: "applicationFeedbackPriority ",
	applicationFeedbackClassification: "applicationFeedbackClassification",
	applicationMissingPartToolReason: "applicationMissingPartToolReason ",
	applicationSkipTaskReason: "applicationSkipTaskReason  ",
	applicationDefectType: "applicationDefectType",
	applicationPauseSubcategory: "applicationPauseSubcategory",
	applicationPosition: "applicationPosition",
	serviceTask: "serviceTask",
	serviceSession: "serviceSession",
	serviceTaskQuestion: "serviceTaskQuestion",
	serviceTaskQuestionOption: "serviceTaskQuestionOption",
	serviceSessionQuestion: "serviceSessionQuestion",
	serviceSessionQuestionOption: "serviceSessionQuestionOption",
	serviceNote: "serviceNote",
	serviceMissingPartTool: "serviceMissingPartTool",
	servicePause: "servicePause",
	serviceStatusChange: "serviceStatusChange",
	defectNote: "defectNote",
	defectImage: "defectImage",
	defectPart: "defectPart",
	clientUserSiteAppServiceModel: "clientUserSiteAppServiceModel",
	feedbackNote: "feedbackNote",
	noticeBoardDepartment: "noticeBoardDepartment",
	modelVersionDepartment: "modelVersionDepartment",
	modelImport: "modelImport",
	modelImportAction: "modelImportAction",
	modelImportLubricant: "modelImportLubricant",
	modelImportOperatingMode: "modelImportOperatingMode",
	modelTaskNote: "modelTaskNote",
	modelVersionTask: "modelVersionTask",
	modelIntervalTaskListNo: "modelIntervalTaskListNo",
	modelVersionQuestionRole: "modelVersionQuestionRole",
	modelVersionIntervalInclude: "modelVersionIntervalInclude",
	modelVersionsQuestionOption: "modelVersionsQuestionOption",
	modelVersionTaskStage: "modelVersionTaskStage",
	modelVersionTaskZone: "modelVersionTaskZone",
	modelVersionTaskRole: "modelVersionTaskRole",
	modelVersionTaskInterval: "modelVersionTaskInterval",
	modelVersionTaskTool: "modelVersionTaskTool",
	modelVersionTaskPart: "modelVersionTaskPart",
	modelVersionTaskPermit: "modelVersionTaskPermit",
	modelVersionTaskImage: "modelVersionTaskImage",
	modelVersionTaskDocument: "modelVersionTaskDocument",
	modelVersionTaskQuestion: "modelVersionTaskQuestion",
	modelVersionTaskQuestionOption: "modelVersionTaskQuestionOption",
	modeImportAction: "modeImportAction",
	modeImportLubricant: "modeImportLubricant",
	ModeImportOperatingMode: "modeImportOperatingMode",
	modeImportSystem: "modeImportSystem",
	modeImportRole: "modeImportRole",
	modelVersionArrangement: "modelVersionArrangement",
	modelVersionTaskArrangement: "modelVersionTaskArrangement",
	modelDocument: "modelDocument",
	modelNote: "modelNote",
	modelAsset: "modelAsset",
	modelVersionStage: "modelVersionStage",
	modelVersionZone: "modelVersionZone",
	modelVersionInterval: "modelVersionInterval",
	modelVersionRole: "modelVersionRole",
	modelVersionQuestion: "modelVersionQuestion",
	applicationChangeStatusReason: "applicationChangeStatusReason",
	user: "user",
	client: "client",
	clientNote: "clientNote",
	clientApplictaion: "clientApplictaion",
	clientDocument: "clientDocument",
	region: "region",
	site: "site",
	siteDepartment: "siteDepartment",
	siteAsset: "siteAsset",
	pause: "pause",
	pauseSubcategory: "pauseSubcategory",
	missingPartToolReason: "missingPartToolReason",
	siteAssetReference: "siteAssetReference",
	report: "report",
	skipTaskReason: "skipTaskReason",
	stopReason: "stopReason",
	changeStatusReason: "changeStatusReason",
	modelStatus: "modelStatus",
	modelType: "modelType",
	action: "action",
	system: "system",
	operatingMode: "operatingMode",
	lubricant: "lubricant",
	role: "role",
	position: "position",
	defectRiskRating: "defectRiskRating",
	defectStatus: "defectStatus",
	defectType: "defectType",
	feedbackClassification: "feedbackClassification",
	feedbackPriority: "feedbackPriority",
	feedbackStatus: "feedbackStatus",
	siteAppKeyContact: "siteAppKeyContact",
	clientUser: "clientUser",
	clientUserSiteApp: "clientUserSiteApp",
	clientUserNote: "clientUserNote",
	clientUserSiteAppServiceDepartment: "clientUserSiteAppServiceDepartment",
	clientUserSiteAppServiceRole: "clientUserSiteAppServiceRole",
	model: "model",
};
