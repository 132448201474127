import React from "react";
import { TableCell, TableRow } from "@mui/material";
import "./style.scss";
import HtmlTooltip from "components/Elements/HtmlTooltip";

const ClientKeyRow = ({ row }) => {
	let rowData = [];

	for (const property in row) {
		if (property !== "id") {
			if (property === "application" || property === "email") {
				rowData.push(
					<TableCell style={{ wordBreak: "break-word" }} key={row[property]}>
						<HtmlTooltip title={row.email}>
							<p className="max-two-line">{row[property]}</p>
						</HtmlTooltip>
					</TableCell>
				);
			} else {
				rowData.push(
					<TableCell className="white-space-nowrap" key={row[property]}>
						{row[property]}
					</TableCell>
				);
			}
		}
	}

	return <TableRow>{rowData}</TableRow>;
};

export default ClientKeyRow;
