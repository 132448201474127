import { TableCell, TableRow } from "@mui/material";
import { ReactComponent as DeleteIcon } from "assets/icons/deleteIcon.svg";
import HtmlTooltip from "components/Elements/HtmlTooltip";
import { changeDate } from "helpers/date";
import React from "react";

const UserNoteRow = ({ row, classes, onViewNote, onDeleteNote }) => {
	return (
		<TableRow>
			<TableCell style={{ width: "170px" }}>{row.name}</TableCell>
			<TableCell style={{ width: "100px" }}>{changeDate(row.date)}</TableCell>
			<TableCell className="note-field">
				<HtmlTooltip title={row.note}>
					<p className="max-two-line" style={{ whiteSpace: "break-spaces" }}>
						{row.note}
					</p>
				</HtmlTooltip>
			</TableCell>
			<TableCell>
				<p className="new-link" onClick={() => onViewNote(row.note)}>
					View
				</p>
			</TableCell>
			<TableCell style={{ width: "50px" }}>
				<DeleteIcon style={{ cursor: "pointer" }} onClick={onDeleteNote} />
			</TableCell>
		</TableRow>
	);
};

export default UserNoteRow;
