import { TableCell, TableRow } from "@mui/material";
import HtmlTooltip from "components/Elements/HtmlTooltip";

import React from "react";

const NotesRow = ({
	row,
	classes,
	onDeleteNote,
	onViewNote,
	isReadOnly,
	isPrint,
}) => {
	return (
		<TableRow>
			<TableCell style={{ width: "300px" }}>{row?.name}</TableCell>
			<TableCell style={{ width: "300px" }}>{row?.date}</TableCell>
			<TableCell
				className="note-field"
				style={{ whiteSpace: "unset", maxWidth: "500px" }}
			>
				<HtmlTooltip title={row.note}>
					<p className={isPrint ? "" : "max-two-line"}>{row?.note}</p>
				</HtmlTooltip>
			</TableCell>
		</TableRow>
	);
};

export default NotesRow;
