import React from "react";
import PropTypes from "prop-types";
import { FULL_ACCESS } from "constants/AccessTypes/AccessTypes";

function AccessWrapper({ children, access, accessList }) {
	if (accessList.includes(access) || access === FULL_ACCESS) return <>{children}</>;
	return null;
}

AccessWrapper.defaultProps = {
	access: "N",
	accessList: [FULL_ACCESS],
};

AccessWrapper.propTypes = {
	children: PropTypes.any.isRequired,
	access: PropTypes.string,
	accessList: PropTypes.arrayOf(PropTypes.string),
};

export default AccessWrapper;
