import { Tooltip } from "@mui/material";
import { styled } from "@mui/system";

const HtmlTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ tooltip: className }} />
))(({ theme }) => ({
	backgroundColor: "#f5f5f9",
	color: "rgba(0, 0, 0, 0.87)",
	maxWidth: 220,
	fontSize: theme.typography.pxToRem(12),
	border: "1px solid #dadde9",
}));

export default HtmlTooltip;
